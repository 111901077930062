import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faDownload } from '@fortawesome/pro-light-svg-icons'
import { faGift, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { faLoader } from '@fortawesome/pro-duotone-svg-icons'
import './App.scss';
import ProudPledger from './proud-pledger.png';

class GivingTuesdayPledgeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      pledgeCount: 0,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.setPledgeCount = this.setPledgeCount.bind(this);
    this.blob;
  }

  async componentDidMount() {
    // console.log('componentDidMount');
    await this.setPledgeCount();
    var intervalId = setInterval(await this.setPledgeCount, 60000);
    this.setState({intervalId: intervalId});

    this.blob = await fetch(ProudPledger).then(r=>r.blob());
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  async getPledgeCount(group_id) {
    const formData = new FormData();
    formData.append('group_id', group_id);

    const res = await fetch( 'https://classyapi.crosby.co/api/subscribers', {
      method: "POST",
      body: new URLSearchParams(formData),
      mode: "cors"
    });

    return await res.json();
  }

  async setPledgeCount() {
    // console.log('setPledgeCount');
    let res = await this.getPledgeCount('58987591');
    this.setState({ pledgeCount: res.active_count });
  }

  handleSubmit( event=false ) {
    if(event)
      event.preventDefault();

    var formSubmitBtn = document.getElementById('givingTuesdayPledgeFormSubmitBtn');
    
    formSubmitBtn.classList.add('btn--loading');

    var formData = new FormData(event.target);
    let formUrl = event.target.getAttribute('action');

    try {
      fetch(formUrl, {
        method: "POST",
        body: new URLSearchParams(formData),
        mode: "cors",
      })
      .then((res) => {
        // console.log(res);
      });

      formSubmitBtn.classList.remove('btn--loading');
      this.setState({ submitted: true });
    } catch( err ) {
      console.log(err);
    }
  }

  copyImage() {
    // console.log('copy image');
    try {
      navigator.clipboard.write([
        new ClipboardItem({
          'image/png': this.blob
        })
      ]);
    } catch( err ) {
      console.log( err );
    }
  }

  shareImage() {
    // console.log('share image');
    const imgData = {
      files: [
        new File([this.blob], 'Social-Image.png', {
          type: this.blob.type
        }),
      ],
      url: 'https://lovetotherescue.org/giving-tuesday',
      title: 'Proud Pledger',
      text: 'This #GivingTuesday, I’ve pledged to donate to Shriners Children’s and make life-changing medical care possible for kids who need it. Will you pledge too?',
    };
    try {
      if (!(navigator.canShare(imgData))) {
        throw new Error("Can't share data.", imgData);
      }
      navigator.share(imgData);
    } catch( err ) {
      console.log( err );
    }
  }

  render() {
    return (
      <div className="GivingTuesdayPledge--Form">
        
        { this.state.submitted === false && 
          <>
            <header className="GivingTuesdayPledge--App-header">
              Pledge to make a gift that will TRIPLE This Giving Tuesday
              <hr></hr>
            </header>

            <div className="GivingTuesdayPledge--App-subHeader">
              <strong>Giving Tuesday is December 3.</strong> Our 3x Match Challenge means your donation will be tripled! Fill out the form below to pledge your support and we’ll send you an email reminder when it’s time to donate.
            </div>

            <div className="GivingTuesdayPledge--App-layout">
              
              <div className="GivingTuesdayPledge--Pledge-container">
                <div className="GivingTuesdayPledge--Pledge-gifts">
                  <div className="GivingTuesdayPledge--Pledge-giftsIcon"><FontAwesomeIcon icon={faGift} /></div>
                  <div id="givingTuesdayPledgeCount" className="GivingTuesdayPledge--Pledge-giftsCount">{this.state.pledgeCount}</div>
                </div>
                <div className="GivingTuesdayPledge--Pledge-giftsLabel">people have pledged to give on Giving Tuesday</div>
              </div>

              <div className="GivingTuesdayPledge--App-layoutDivider"></div>

              <div className="GivingTuesdayPledge--Form-container">
                <div className="GivingTuesdayPledge--Form-header">Count me in!</div>

                <form id="givingTuesdayPledgeForm" action="https://classyapi.crosby.co/api/subscribe" method="post" onSubmit={this.handleSubmit}>
                  <input type="hidden" name="form_id" value="gt-pledge-2024" />
                  <div className="row">
                    <div className="col">
                      <input type="text" name="first_name" className="form-control" placeholder="First name" aria-label="First name" required/>
                    </div>
                    <div className="col">
                      <input type="text" name="last_name" className="form-control" placeholder="Last name" aria-label="Last name" required/>
                    </div>
                    <div className="col-12">
                      <input type="email" name="email" className="form-control" placeholder="Email" aria-label="Email" required/>
                    </div>
                    <div className="col-12">
                      <button type="submit" id="givingTuesdayPledgeFormSubmitBtn" className="btn">Make the Pledge <FontAwesomeIcon id="givingTuesdayPledgeFormSubmitIcon" icon={faChevronRight}/><FontAwesomeIcon id="givingTuesdayPledgeFormLoadingIcon" icon={faLoader} spinPulse/></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="GivingTuesdayPledge--App-footer">
              <p>When you pledge to give to Shriners Children’s, you pledge to help kids live healthier, more active lives. It’s a heartwarming way to create special experiences for the millions of children who rely on donors like you.</p>
            </div>
          </>
        }

        { this.state.submitted === true && 
          <div>
            <header className="GivingTuesdayPledge--App-header">
              Thank you for your pledge!
              <hr></hr>
            </header>

            <div className="GivingTuesdayPledge--Form-intro">
              You can support our efforts while you wait for Giving Tuesday – let your friends know about your pledge and invite them to join you in changing lives.   
            </div>

            <div className="GivingTuesdayPledge--Form-share">
              <div className="GivingTuesdayPledge--Form-share-img">
                <img id="ProudPledgeImg" src={ProudPledger} />
              </div>
              <div className="GivingTuesdayPledge--Form-share-content">
                <div className="GivingTuesdayPledge--Form-share-header">Share with your friends</div>

                <div className="GivingTuesdayPledge--Form-share-body">
                  This #GivingTuesday, I’ve pledged to donate to Shriners Children’s and make life-changing medical care possible for kids who need it. Will you pledge too? <span className="hidden-mobile"><br/><br/>Copy and share the link below.</span><br/><a href="https://lovetotherescue.org/giving-tuesday">https://lovetotherescue.org/giving-tuesday</a>
                </div>

                <div className="GivingTuesdayPledge--Form-share-links">
                  <span className="GivingTuesdayPledge--Form-share-link">
                    <FontAwesomeIcon icon={faDownload} /> <a className="" href={ProudPledger} target="_blank" download="Social-Image.png">Download Image</a>
                  </span>
                  <span className="GivingTuesdayPledge--Form-share-link">
                    <FontAwesomeIcon icon={faCopy} /> <a className="" onClick={this.copyImage}>Copy Image</a>
                  </span>
                </div>

                <div className="GivingTuesdayPledge--Form-share-btn">
                  <button className="btn" onClick={this.shareImage}>Share</button>
                </div>

              </div>
            </div>

          </div>
        }
      </div>
    );
  }
}

class App extends Component {
  render() {
    return (
      <div className="GivingTuesdayPledge--App">

        <GivingTuesdayPledgeForm/>

      </div>
    );
  }
}

export default App;
